<template>
  <!-- 意见反馈 -->
  <div class="box-card" style="text-align: left;margin-top: 20px;">
    <le-card title="意见反馈">
      <template slot="heard-right">
        <div class="right">
          <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('1690e2a2b9b6480baa4c04a3')">删除
          </lbButton>
        </div>
      </template>
      <!-- 主要内容 -->
      <div style="text-align:left;">
        <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
          @submit.native.prevent>
          <el-form-item label="账号：">
            <el-input maxlength="30" v-model="queryForm.account" @input="(e) => (queryForm.account = validSpace(e))"
              @keyup.enter.native="onSubmit" placeholder="请输入账号"></el-input>
          </el-form-item>
          <el-form-item label="内容：">
            <el-input maxlength="300" v-model="queryForm.content" @input="(e) => (queryForm.content = validSpacing(e))"
              @keyup.enter.native="onSubmit" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="手机端：">
            <el-select popper-class="my-select" clearable v-model="queryForm.type" default-first-option
              placeholder="请选择手机端" @change="onSubmit()">
              <el-option v-for="item in phoneSelectList" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
          </el-form-item>
        </el-form>
        <!-- 使用插件生产表格 -->
        <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
          @size-change="sizeChange" @current-change="currentChange">
          <template slot-scope="{row}" slot="type">
            {{ getWayName('GroupPhoneClient', row.type) }}
          </template>
          <template slot-scope="{row}" slot="create_time">
            {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
          </template>
        </avue-crud> -->
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="50" align="center" header-align="center"></el-table-column>
        <el-table-column prop="account" label="账号"></el-table-column>
        <el-table-column label="手机端">
          <template slot-scope="scope">{{getWayName('GroupPhoneClient', scope.row.type)}}</template>
        </el-table-column>
        <el-table-column prop="content" label="内容" min-width="120"></el-table-column>
        <el-table-column label="创建时间">
          <template slot-scope="scope">
            {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
          </template>
        </el-table-column>
      </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </le-card>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import { getFeedBackPageList, delFeedBack } from '@/api/maintenanceCenter'
export default {
  data () {
    return {
      queryForm: {
        PageSize: 10,
        PageIndex: 1,
        account: '',
        content: '',
        type: null // 1.巡检员  2.车主 3.商家
      },
      total: 0,
      // 表格数据
      tableData: [],
      // 选中的表格数据
      multipleSelection: [],
      phoneSelectList: [],
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: false,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '账号',
            prop: 'account'
          }, {
            label: '手机端',
            prop: 'type',
            slot: true
          }, {
            label: '内容',
            prop: 'content'
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    this.fngetFeedBackPageList()
    this.phoneSelectList = this.getOptions('GroupPhoneClient').concat().filter(item => {
      return item.name.indexOf('访客登记') === -1
    })
  },
  mounted () { },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['getOptions', 'getWayName', 'getAllParkingList'])
  },
  watch: {},
  methods: {
    async fngetFeedBackPageList () {
      const res = await getFeedBackPageList({
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex,
        account: this.queryForm.account,
        content: this.queryForm.content,
        type: this.queryForm.type ? this.queryForm.type : 0
      })
      console.log(res)
      this.tableData = res && res.Code === 200 && res.Data ? res.Data.DataList : []
      this.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fngetFeedBackPageList()
    },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // // 选中的数据
    // selectionChange (list) {
    //   this.multipleSelection = list
    // },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fngetFeedBackPageList()
    // },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fngetFeedBackPageList()
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fngetFeedBackPageList()
    //   this.requestDataList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`);
      this.queryForm.PageIndex = val
      this.fngetFeedBackPageList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // 批量删除
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的意见, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var obj = {}
          var arr = []
          for (var i = 0; i < this.multipleSelection.length; i++) {
            arr.push(this.multipleSelection[i]._id)
            if (arr.length === this.multipleSelection.length) {
              obj._ids = arr
              this.fndelFeedBack(obj)
            }
          }
        }).catch(() => {
          this.$msg.info('已取消删除')
        })
      } else {
        this.$msg.warning('请选择要删除的意见！')
      }
    },
    async fndelFeedBack (obj) {
      const res = await delFeedBack(obj)
      if (res && res.Code === 200) this.$msg.success(res.Message)
      this.fngetFeedBackPageList()
    }
  }
}
</script>
<style scoped lang="less"></style>
